import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import SidebarPromotionOne from '../components/Marketing/SidebarPromotionOne'
const Terms = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Terms of Use"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Terms Of Use"
            />
            <section className="terms-of-service-area ptb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">
                                <p><i>This Terms of Use was last updated on January 1, 2023.</i></p>
                                <blockquote className="blockquote">
                                    <p>Welcome to Perisun's website. These terms and conditions outline the rules and regulations for the use of our website
                                        .By accessing this website, we assume that you accept these terms and conditions in full. If you disagree with these terms
                                        and conditions, you should not use this website.</p>
                                </blockquote>
                                <h3>1. Intellectual Property Rights</h3>
                                <p> The contents of this website, including but not limited to text, graphics, images, logos, icons, audio and video files, are the property of Perisun and are protected by copyright laws. You are not authorized to use, copy or reproduce any content from this website without prior written permission from Perisun.</p>
                                <h3>2. Use of Website</h3>
                                <p> You agree to use this website only for lawful purposes and in a manner that does not infringe the rights of, or restrict or inhibit the use and enjoyment of this website by, any third party.</p>
                                <h3>3. User Content</h3>
                                <p>You may be able to post or submit comments, feedback, or other materials on this website. By doing so, you agree that Perisun may use, copy, modify, adapt, translate, publish, display, distribute, and otherwise exploit such content in any media now known or hereafter developed.</p>
                                <h3>4. Privacy Policy</h3>
                                <p>Perisun is committed to protecting your privacy. Our Privacy Policy explains how we collect, use, and protect your personal information when you use our website.</p>
                                <h3>5. Links to Other Websites</h3>
                                <p>This website may contain links to other websites that are not owned or controlled by Perisun. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites.</p>
                                <h3>Limitation of Liability</h3>
                                <p>Perisun will not be liable for any damages, including indirect, incidental, special, or consequential damages, arising out of or in connection with the use or inability to use this website.</p>
                                <h3>Governing Law </h3>
                                <p>These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction of Haryana, India. Any disputes arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Jhajjar, Haryana only.</p>
                                <blockquote className="blockquote">
                                    <p>By using this website, you agree to comply with these terms and conditions. Perisun reserves the right to change or modify
                                        these terms and conditions at any time without prior notice.</p>
                                </blockquote>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <SidebarPromotionOne />
                            </aside>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    );
}

export default Terms